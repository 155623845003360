import { useCallback, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import cn from 'classnames';
import isNil from 'lodash/isNil';
import { useForm, useController, Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import Accordion from '@/components/atoms/Accordion/Accordion';
import CodeBlock from '@/components/atoms/CodeBlock/CodeBlock';
import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import OptionRadioButton from '@/components/atoms/OptionRadioButton/OptionRadioButton';
import Select from '@/components/atoms/Select/Select';
import Switch from '@/components/atoms/Switch/Switch';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { useIntegrations } from '@/hooks/useIntegrations';
import usePrompt from '@/hooks/usePrompt';
import { OptionBase } from '@/models/common';
import {
  AccordionFieldsetProps,
  Integration,
  WebWidgetConfig,
  WebWidgetIntegration,
} from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { setDirty, setExpanded } from '@/redux/integrations/actions';
import { selectAccordion } from '@/redux/integrations/selectors';
import { getPermissions } from '@/redux/permissions/selectors';
import { getDocsUrl } from '@/util/constants';
import { languageAndFlagByCountryCode } from '@/util/languageCodes';
import { decodeBase64, encodeBase64 } from '@/util/util';
import { customerRsaKey, errorMessage, isRequired } from '@/util/validator';

import styles from './AdvancedSettings.module.scss';

type Form = {
  customer_rsa_key: string;
  encryption_activation: boolean;
  sticky_session?: boolean;
  show_survey?: boolean;
  language?: string;
  external_urls_limit?: number;
  file_upload?: boolean;
};

const SOURCES_MAX_LIMIT = 8;

const sourcesLimitOptions = Array.from(
  { length: SOURCES_MAX_LIMIT },
  (_, i) => ({
    label: `${i + 1}`,
    value: i + 1,
  })
);

const AdvancedSettings = ({
  type,
  integration,
  toggleAccordion,
  registerAccordion,
}: AccordionFieldsetProps<Form, Integration>) => {
  const integrationConfig = integration?.config as WebWidgetConfig;
  const { t } = useTranslation();
  const { expanded } = useSelector(selectAccordion);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );
  const integration_id = integration?.integration_id;
  const desk_id = integration?.desk_id;
  const integrationType = integration?.type;
  const { updateIntegration, updateStatus } =
    useIntegrations<WebWidgetIntegration>(desk_id, integration_id);
  const dispatch = useDispatch();

  const [generatedPublicKey, setGeneratedPublicKey] = useState('');
  const [showSourceLimit, setShowSourceLimit] = useState(
    !isNil(integrationConfig?.external_urls_limit)
  );
  const [languageOptions, setLanguageOptions] = useState<OptionBase[]>();

  const formMethods = useForm<Form>({
    mode: 'onSubmit',
  });
  const {
    control,
    register,
    reset,
    formState: { errors, isDirty },
    handleSubmit,
    trigger,
    setValue,
    getValues,
  } = formMethods;

  const { field } = useController({
    name: 'sticky_session',
    control,
  });

  const activation = useWatch({ name: 'encryption_activation', control });

  useEffect(() => {
    reset({
      customer_rsa_key:
        decodeBase64(integrationConfig?.public_customer_rsa_key) || '',
      sticky_session: integrationConfig?.sticky_session ?? false,
      encryption_activation: !!integrationConfig?.public_customer_rsa_key,
      show_survey: integrationConfig?.show_survey ?? true,
      language: integrationConfig?.language || 'default',
      external_urls_limit: integrationConfig?.external_urls_limit ?? undefined,
      file_upload: integrationConfig?.file_upload,
    });
  }, [
    integrationConfig?.file_upload,
    integrationConfig?.show_survey,
    integrationConfig?.language,
    integrationConfig?.public_customer_rsa_key,
    integrationConfig?.sticky_session,
    integrationConfig?.external_urls_limit,
    reset,
  ]);

  useEffect(() => {
    if (integrationConfig?.generated_public_key) {
      setGeneratedPublicKey(
        decodeBase64(integrationConfig?.generated_public_key)
      );
    }
  }, [integrationConfig?.generated_public_key]);

  const handleUpdateIntegration = useCallback(() => {
    const {
      customer_rsa_key,
      sticky_session,
      encryption_activation,
      show_survey,
      language,
      external_urls_limit,
      file_upload,
    } = getValues();
    updateIntegration(
      {
        integration_id,
        desk_id,
        type: integrationType as unknown as 'web',
        config: {
          ...integration?.config,
          file_upload: integrationType === 'web' ? file_upload : undefined,
          sticky_session:
            integrationType === 'web' ? sticky_session : undefined,
          external_urls_limit:
            integrationType === 'web' ? external_urls_limit : undefined,
          show_survey: integrationType === 'web' ? show_survey : undefined,
          language:
            integrationType === 'web' && language !== 'default'
              ? language
              : undefined,
          public_customer_rsa_key:
            customer_rsa_key && encryption_activation
              ? encodeBase64(customer_rsa_key)
              : undefined,
        },
      },
      {
        onSuccess: () => {
          if (expanded === type) {
            dispatch(setExpanded(false));
          }
        },
      }
    );
  }, [
    getValues,
    updateIntegration,
    integration_id,
    desk_id,
    integrationType,
    integration?.config,
    expanded,
    type,
    dispatch,
  ]);

  usePrompt(
    isDirty && canWrite,
    undefined,
    undefined,
    handleSubmit(handleUpdateIntegration)
  );

  useEffect(() => {
    dispatch(setDirty(isDirty));
  }, [dispatch, isDirty]);

  useEffect(() => {
    if (expanded !== type && isDirty) {
      reset();
    }
  }, [expanded, isDirty, reset, type]);

  const stickyOptions = useMemo(
    () => [
      {
        id: 'sticky-enable',
        value: 'true',
        label: (
          <TitleSubtitle
            title={t('integrations.sticky_session.conv_hist.r_enable.title')}
            subtitle={t(
              'integrations.sticky_session.conv_hist.r_enable.subtitle'
            )}
            noGutters
          />
        ),
      },
      {
        id: 'sticky-disable',
        value: 'false',
        label: (
          <TitleSubtitle
            title={t('integrations.sticky_session.conv_hist.r_disable.title')}
            subtitle={t(
              'integrations.sticky_session.conv_hist.r_disable.subtitle'
            )}
            noGutters
          />
        ),
      },
    ],
    [t]
  );

  const onRadioChange = useCallback(
    (event) => {
      if (event.target.value === 'true') {
        field.onChange(true);
      } else {
        field.onChange(false);
      }
    },
    [field]
  );

  useEffect(() => {
    let options = [];
    if (integrationType === 'web') {
      options = Object.keys(languageAndFlagByCountryCode).reduce((acc, key) => {
        if (!languageAndFlagByCountryCode[key]?.display_only) {
          return [...acc, { label: t(`languages.${key}`), value: key }];
        }
        return acc;
      }, []);
      setLanguageOptions([
        {
          label: t('integrations.web_language.auto_detect'),
          value: 'default',
        },
        ...options,
      ]);
    }
  }, [t, integrationType]);

  const updateSourceLimitToggle = useCallback(() => {
    if (showSourceLimit) {
      setValue('external_urls_limit', undefined, {
        shouldDirty: true,
      });
    }
    setShowSourceLimit((prev) => !prev);
  }, [setValue, showSourceLimit]);

  return (
    <>
      <Accordion
        title={t('integrations.sticky_session.title')}
        subtitle={t('integrations.sticky_session.subtitle')}
        onSubmit={handleSubmit(handleUpdateIntegration)}
        disabled={!isDirty}
        isLoading={updateStatus === 'pending'}
        expanded={expanded === type}
        handleChange={toggleAccordion(type)}
        readOnly={!canWrite}
        ref={registerAccordion(
          type,
          handleSubmit(handleUpdateIntegration),
          trigger
        )}
      >
        <>
          {integrationType === 'web' && (
            <>
              <div className={styles.container}>
                <TitleSubtitle
                  title={t('integrations.file_upload.title')}
                  subtitle={t('integrations.file_upload.subtitle')}
                />
                <Controller
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Switch
                        label={t(value ? 'common.enabled' : 'common.disabled')}
                        checked={Boolean(value)}
                        disabled={!integration || !canWrite}
                        size="medium"
                        onChange={onChange}
                      />
                    );
                  }}
                  name="file_upload"
                  control={control}
                />
              </div>
              <div className={cn(styles.sticky, styles.container)}>
                <TitleSubtitle
                  title={t('integrations.sticky_session.conv_hist.title')}
                  subtitle={t('integrations.sticky_session.conv_hist.subtitle')}
                />
                <div className={styles.radioGroup}>
                  {stickyOptions.map((option, index) => (
                    <OptionRadioButton
                      key={`${option.id}-${field.value}`}
                      option={option}
                      size="medium"
                      onChange={onRadioChange}
                      isSelected={field.value === (index === 0 ? true : false)}
                      disableSelectedStyles
                      disabled={!canWrite}
                    />
                  ))}
                </div>
              </div>
              <TitleSubtitle
                title={t('integrations.sources_limit_title')}
                subtitle={t('integrations.sources_limit_subtitle')}
              />
              <Switch
                label={t(
                  showSourceLimit ? 'common.enabled' : 'common.disabled'
                )}
                checked={showSourceLimit}
                disabled={!integration || !canWrite}
                size="medium"
                onChange={updateSourceLimitToggle}
              />
              <Box mt={3}>
                {showSourceLimit && (
                  <Select
                    name="external_urls_limit"
                    register={register('external_urls_limit', isRequired)}
                    errorMessage={errors?.external_urls_limit?.message}
                    options={sourcesLimitOptions}
                    label={t('integrations.sources_select')}
                  />
                )}
              </Box>

              <div className={styles.container}>
                <TitleSubtitle
                  title={t('integrations.show_survey.title')}
                  subtitle={t('integrations.show_survey.subtitle')}
                />

                <Controller
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Switch
                        label={t(
                          value
                            ? 'integrations.fieldsets.active_fieldset.active'
                            : 'integrations.fieldsets.active_fieldset.inactive'
                        )}
                        checked={value}
                        disabled={!integration || !canWrite}
                        size="medium"
                        onChange={onChange}
                      />
                    );
                  }}
                  name="show_survey"
                  control={control}
                />
              </div>
              <div className={styles.container}>
                <TitleSubtitle
                  title={t('integrations.web_language.title')}
                  subtitle={t('integrations.web_language.subtitle')}
                  externalLink={{
                    label: t('integrations.web_language.external_link'),
                    url: getDocsUrl(
                      '/docs/integrations/web_override_configuration#webchat-default-system-language'
                    ),
                  }}
                />
                <Select
                  name="language"
                  register={register('language', isRequired)}
                  errorMessage={errors.language?.message}
                  options={languageOptions}
                />
              </div>
            </>
          )}
          <div className={styles.container}>
            <TitleSubtitle
              title={t('integrations.security.checkbox.title')}
              subtitle={t('integrations.security.checkbox.subtitle', {
                0: integration?.type,
              })}
            />
            <Controller
              render={({ field: { onChange, value } }) => {
                return (
                  <Switch
                    label={t(
                      value
                        ? 'integrations.fieldsets.active_fieldset.active'
                        : 'integrations.fieldsets.active_fieldset.inactive'
                    )}
                    checked={value}
                    disabled={!integration || !canWrite}
                    size="medium"
                    onChange={onChange}
                  />
                );
              }}
              name="encryption_activation"
              control={control}
            />
          </div>
          <ContextualHelp
            title={t('integrations.security.help.title')}
            name="encryption"
            links={[
              {
                label: t('integrations.security.help.label'),
                url: getDocsUrl('/docs/integrations/web_authentication'),
              },
            ]}
          >
            {t('integrations.security.help.message')}
          </ContextualHelp>
          {activation && (
            <>
              <div className={styles.textarea}>
                <TextAreaAsInput
                  id="customer_rsa_key"
                  name="customer_rsa_key"
                  label={t('integrations.security.input_label')}
                  placeholder={t('integrations.security.placeholder')}
                  size="large"
                  register={register('customer_rsa_key', customerRsaKey)}
                  trimValue
                  errorMessage={errorMessage({
                    field: errors.customer_rsa_key,
                    maxLength: customerRsaKey.maxLength,
                  })}
                  tooltip={t('integrations.security.tooltip')}
                  disabled={!canWrite}
                />
              </div>
              <div className={styles.publicKey}>
                <span className={styles.title}>
                  {t('integrations.security.public_key')}
                </span>
                <div className={styles.codeBlockBontainer}>
                  <CodeBlock isPreTag>{generatedPublicKey}</CodeBlock>
                  {generatedPublicKey && (
                    <CopyButton
                      className={styles.copyPublicKey}
                      data={generatedPublicKey}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </>
      </Accordion>
    </>
  );
};

export default AdvancedSettings;
