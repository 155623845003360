import { Dispatch, SetStateAction, useState } from 'react';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button/Button/Button';
import DropdownList from '@/components/atoms/DropdownList/DropdownList';
import PlusCircle from '@/components/atoms/Icons/PlusCircle';
import Sorting from '@/components/atoms/Icons/Sorting';
import SearchBar from '@/components/atoms/SearchBar/SearchBar';
import Header from '@/components/organisms/Header/Header';

import styles from './RichHeader.module.scss';

interface Props {
  handleChange?: (value: string) => void;
  setFilter?: Dispatch<SetStateAction<string>>;
  disabled?: boolean;
  onButtonClick: () => void;
  tooltip?: string;
  name?: string;
  sortingOptions?: string[];
}

export const RichHeader = ({
  handleChange,
  setFilter,
  disabled,
  onButtonClick,
  tooltip,
  name,
  sortingOptions = [],
}: Props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string>(
    t(`brains.${sortingOptions?.[0]}`)
  );

  const options = sortingOptions.map((option) => ({
    label: t(`brains.${option}`),
    value: option,
  }));

  return (
    <Header>
      <Header.Body>
        <div className={styles.brainsWrapper}>
          <Header.Title
            title={
              <Typography
                variant="heading1-medium"
                mr="var(--space-24)"
                color="var(--text-default-gray)"
              >
                {t(name, { count: 2 })}
              </Typography>
            }
          />
          {options.length > 0 && (
            <DropdownList
              options={options}
              size="xlarge"
              optionClick={(item) => {
                setSelected(item.label);
                handleChange(item.value);
              }}
              selected={selected}
            >
              <div className={styles.brainsLabel}>
                <Sorting color="var(--icon-default-blue)" />
                <Typography variant="body-medium">{selected}</Typography>
              </div>
            </DropdownList>
          )}
          <SearchBar
            className={styles.searchBar}
            alwaysExpanded
            setFilter={setFilter}
          />
        </div>
        <Header.Actions>
          <Tooltip arrow title={tooltip}>
            <span>
              <Button onClick={onButtonClick} disabled={disabled}>
                <PlusCircle
                  color={
                    disabled
                      ? 'var(--icon-disabled-gray)'
                      : 'var(--icon-default-white)'
                  }
                />
                {t('common.add_new', {
                  0: t(name, { count: 1 }).toLowerCase(),
                })}
              </Button>
            </span>
          </Tooltip>
        </Header.Actions>
      </Header.Body>
    </Header>
  );
};
