import { RequireAtLeastOne } from 'type-fest';

import { Pagination } from './common';

export enum DatasourceStatus {
  FAILED = 'failed',
  PENDING = 'pending',
  INVALID = 'invalid',
  PROCESSING = 'processing',
  AVAILABLE = 'available',
}

export enum DocumentStatus {
  FAILED = 'failed',
  PENDING = 'pending',
  AVAILABLE = 'available',
  INDEXING = 'indexing',
}

export type Datable = {
  readonly created?: string;
  readonly updated?: string;
};

export type Collection = {
  account_id: string;
  collection_id: string;
  name: string;
  language: string;
  created: string;
  updated: string;
  model_id: string;
  fragment_count: number;
};

export type Collections = {
  collections: Collection[];
};

export type PartialCollection = RequireAtLeastOne<
  Partial<Collection>,
  'collection_id'
>;

export enum DatasourceType {
  WEBSITE = 'website',
  ZENDESK_KB = 'zendesk_kb',
  INTERCOM_KB = 'intercom_kb',
  FILES = 'files',
  S3 = 's3',
  DROPBOX = 'dropbox',
  GDRIVE = 'gdrive',
}

export interface WebsiteConfig {
  seed_urls?: string[];
  single_urls?: string[];
  sitemap_urls?: string[];
  exclude_urls?: string[];
  user_agent?: string;
  cookies?: Record<string, string>;
  auth?: {
    username: string;
    password: string;
  };
}

export interface ZendeskConfig {
  subdomain: string;
  access_token: string;
  locales?: string[];
  email: string;
}

export interface IntercomConfig {
  access_token: string;
  admin_id: string;
  admin_name: string;
  page_id: string;
  page_name: string;
}

export interface FileConfig {
  locale: string;
}

export type Datasource = {
  type: DatasourceType;
  datasource_id: string;
  collection_id: string;
  pending_count: number;
  status: DatasourceStatus;
  name: string;
  enabled: boolean;
  config: ZendeskConfig | IntercomConfig | WebsiteConfig | FileConfig;

  document_count: number;
  indexing_count: number;
  failed_count: number;

  last_indexed_at?: string;
  created_by?: string;

  last_indexed_details?: {
    start_time: number;
    end_time: number;
    errors: string[];
    recommedations: string[];
    warnings: string[];
  };
} & Datable;

export type Datasources = {
  datasources: Datasource[];
};

export type PartialDatasource = RequireAtLeastOne<
  Partial<Datasource>,
  'datasource_id'
>;

export type DocumentErrorCode =
  | 'doc_200'
  | 'doc_500'
  | 'doc_601'
  | 'doc_603'
  | 'doc_604';

export type Document = {
  name: string;
  type: string;
  file_url: string;
  datasource_id: string;
  document_id: string;
  status: DocumentStatus;
  external_url: string;
  size_bytes: number;
  enabled: boolean;
  digest: string;
  status_code: DocumentErrorCode;
  statistics: {
    fragment?: number;
    sessions?: number;
    views?: number;
  };
} & Datable;

export type Documents = {
  documents: Document[];
} & Pagination;

export type PartialDocument = RequireAtLeastOne<
  Partial<Document>,
  'document_id'
>;
