import i18n, { TFunction, t } from 'i18next';
import omitBy from 'lodash/omitBy';
import { Path, PathValue, Validate, ValidationRule } from 'react-hook-form';
import * as yup from 'yup';

import { getCharCount } from '@/components/organisms/RichTextEditor/utils';
import { defaultActionOptions } from '@/components/organisms/Toolkit/ToolkitResponses/ToolkitActionCarousel/constants';

export const HANDOVER_OPTIONS = {
  DEFAULT: 'default',
  ZENDESK_DEPARTMENT_ROUTING: 'zendesk_department_routing',
  FACEBOOK_MESSENGER_HANDOVER: 'facebook_messenger_handover',
} as const;

import { MAX_EMAIL_RECIPIENTS } from './constants';

export const LENGTH_XXS = 16;
export const LENGTH_XS = 32;
export const MAX_DIALOG_NAME_LENGTH = 45;
export const LENGTH_S = 64;
export const LENGTH_M = 128;
export const LENGTH_L = 256;
export const LENGTH_XL = 512;
export const LENGTH_XXL = 1000;
export const LENGTH_1024 = 1024;
export const LENGTH_XXXL = 4096;
export const LENGTH_XXXXL = 10000;

type ErrorMessageOptions = Partial<{
  field: { type?: string; message?: string };
  maxLength: ValidationRule<number>;
  minLength: ValidationRule<number>;
  maxValue: ValidationRule<number>;
  minValue: ValidationRule<number>;
}>;

export const errorMessage = (options: ErrorMessageOptions) => {
  const { field, maxLength, minLength, maxValue, minValue } = options || {};

  if (!field) {
    return null;
  }

  if (field?.message) {
    return field.message;
  }

  if (field?.type === 'required') {
    return i18n.t('validation.required');
  }

  if (field?.type === 'minLength') {
    return i18n.t('validation.at_least', { 0: minLength?.toString() });
  }

  if (field?.type === 'maxLength') {
    return i18n.t('validation.less_than', { 0: maxLength?.toString() });
  }

  if (field?.type === 'pattern') {
    return i18n.t('validation.pattern');
  }

  if (field?.type === 'maxValue') {
    return i18n.t('validation.max_value', { 0: maxValue?.toString() });
  }

  if (field?.type === 'minValue') {
    return i18n.t('validation.min_value', { 0: minValue?.toString() });
  }

  return null;
};

// Regular expressions for nodeId, tag, metadata, slug and context variable
export const slug = /^[\w][\w-]*$/i;

const accountSlugRegex = /^[a-z\d](?:[a-z\d]|-(?=[a-z\d])){0,31}$/i;

const delayInSeconds = /^([1-9]|[1-9][0-9]|[1-2][0-9][0-9]|300)$/i;

const delayInMinutes = /^[1-5]$/i;

export const isValidDelayInMinutes = (minutes: string): boolean =>
  delayInMinutes.test(minutes);

export const isValidDelayInSeconds = (seconds: string): boolean =>
  delayInSeconds.test(seconds);

export const isValidAccountSlug = (slug: string): boolean =>
  accountSlugRegex.test(slug);

export const isValidSlug = (text: string): boolean => slug.test(text);

/*
 * Matching examples:
 * $variable
 * $vari-able
 * $example.word
 * hello@world
 */
export const checkForPattern = new RegExp(
  /^\$[\w][\w-]*(?:\.[\w-]+)*|@[\w][\w-]*$/i
);

/*
 * Matching examples:
 * {{$variable}}
 * {{$vari-able}}
 * {{$example.word}}
 * {{hello@world}}
 */
export const contextVariableWithBraces = /^{{\$[\w][\w-]*(?:\.[\w-]+)*}}$/i;

/*
 * http://www.example.com
 * viber://chat?contact=123456789
 * https://example.com/{{\$dynamicPath}}/resource
 * {{\$customScheme}}://{{\$dynamicDomain}}/{{\$dynamicPath}}
 */
export const contextUrl =
  /^(https|ftp|viber|erparent|({{\$[\w][\w-]*(?:\.[\w-]+)*}})):\/\/(((([\w_-]+(?:(?:\.[\w_-]+)?))|({{\$[\w][\w-]*(?:\.[\w-]+)*}}))((([\w.,@?^=%&:/~+#-]|({{\$[\w][\w-]*(?:\.[\w-]+)*}}))*([\w@?^=%&/~+#-]|({{\$[\w][\w-]*(?:\.[\w-]+)*}})))?({{\$[\w][\w-]*(?:\.[\w-]+)*}})?))|({{\$[\w][\w-]*(?:\.[\w-]+)*}}))$/i;

// url pattern of yup validator
export const urlPattern =
  /^(https|ftp|viber):\/\/([\w_-]+(?:(?:\.[\w_-]+)?))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/i;

export const noUrlPattern =
  /(?:https?|ftp|file|data|javascript|mailto|tel|sms|blob):\/\/[^\s/$.?#].[^\s]*/i;

export const urlWithoutProtocolPattern =
  /^((?:[\w_-]+(?:(?:\.[\w_-]+)?))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?)?$/i;

// email pattern of yup validator
export const emailPattern =
  // eslint-disable-next-line no-control-regex, no-useless-escape
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))){2,}$/i;

/*
 * Matching examples:
 * $a
 * $example
 * $break-word
 * $wordA.wordB
 */
export const contextRefRegExp = new RegExp(/^\$[\w][\w-]*(?:\.[\w-]+)*$/i);

/*
 * Matching examplpes:
 * a
 * example
 * break-word
 * wordA.wordB
 */
export const saveAsRefRegExp = new RegExp(/^[\w][\w-]*(?:\.[\w-]+)*$/i);

export const contextRefSchema = yup.string().matches(contextRefRegExp, {
  message: () => i18n.t('validation.pattern'),
});

export const spreadsheetIdPattern = /^[a-zA-Z0-9-_]+$/i;

export const deskRules = (t: TFunction) => ({
  name: { required: true, maxLength: LENGTH_XS },
  description: { required: true, maxLength: LENGTH_M },
  keepAlive: {
    required: { value: true, message: 'This is a required field' },
    validate: {
      maxValue: (v) =>
        parseInt(v, 10) <= 900 ||
        t('errors.value_boundaries', { 0: 'Max', 1: '900' }),
      minValue: (v) =>
        parseInt(v, 10) >= 1 ||
        t('errors.value_boundaries', { 0: 'Min', 1: '1' }),
    },
  },
});

export const brainRules = {
  name: {
    required: true,
    maxLength: LENGTH_XS,
    validate: {
      noUrl: (value) => {
        return !noUrlPattern.test(value) || t('validation.url_not_allowed');
      },
    },
  },
  description: { maxLength: LENGTH_M },
  disambiguationPrompt: {
    required: true,
    maxLength: LENGTH_M,
  },
  confidenceThreshold: {
    required: true,
    validate: {
      maxValue: (v) => parseFloat(v) <= 1.0,
      minValue: (v) => parseFloat(v) >= 0.0,
    },
  },
  language: {
    required: true,
  },
  inactivityTimeout: (maxInactivityTimeout) => {
    return {
      required: true,
      validate: {
        maxValue: (v) => parseInt(v, 10) <= maxInactivityTimeout,
        minValue: (v) => parseInt(v, 10) >= 60,
      },
    };
  },
};

export const collectionRules = {
  name: (usedNames?: string[]) => ({
    required: true,
    maxLength: LENGTH_XS,
    validate: (value: string) => {
      if (usedNames?.includes(value.toLowerCase())) {
        return i18n.t('validation.name_already_exists');
      }
      if (noUrlPattern.test(value)) {
        return i18n.t('validation.url_not_allowed');
      }
      return true;
    },
  }),
};

export const datasourceRules = {
  name: {
    required: true,
    maxLength: LENGTH_XS,
  },
  url: {
    maxLength: LENGTH_XXL,
    validate: (value: string) =>
      urlWithoutProtocolPattern.test(value) || t('validation.invalid_url'),
  },

  zendesk: {
    subdomain: {
      required: true,
    },
    access_token: {
      required: true,
    },
    locale: {
      required: true,
    },
    email: { required: true, pattern: emailPattern },
  },
};

export const emailRules = (t: TFunction) => ({
  required: {
    value: true,
    message: t('validation.required'),
  },
  pattern: {
    value: emailPattern,
    message: t('errors.invalid_email'),
  },
  maxLength: {
    value: 128,
    message: t('validation.less_than', { 0: LENGTH_M }),
  },
});

export const accountNameRules = (t: TFunction) => ({
  required: {
    value: true,
    message: t('validation.required'),
  },
  maxLength: {
    value: 32,
    message: t('validation.less_than', { 0: LENGTH_XS }),
  },
});

export const businessHoursRules = {
  businessHourName: {
    minLength: {
      value: 2,
      message: 'Should be at least 2 characters long',
    },
    maxLength: {
      value: 24,
      message: 'Should be less or equal than 24 characters long',
    },
    required: { value: true, message: 'This is a required field' },
  },
  holidayName: {
    minLength: {
      value: 2,
      message: 'Should be at least 2 characters long',
    },
    maxLength: {
      value: 24,
      message: 'Should be less or equal than 24 characters long',
    },
    required: { value: true, message: 'This is a required field' },
  },
};

export const modalConnectIntentRules = (t: TFunction) => {
  return {
    dialog_name: {
      required: t('validation.required'),
      maxLength: {
        value: MAX_DIALOG_NAME_LENGTH,
        message: t('validation.less_than', { 0: MAX_DIALOG_NAME_LENGTH }),
      },
    },
    trigger: {
      required: t('validation.required'),
      maxLength: {
        value: LENGTH_S,
        message: t('validation.less_than', { 0: LENGTH_S }),
      },
    },
    response: {
      maxLength: {
        value: 1000,
        message: t('validation.less_than', { 0: 1000 }),
      },
    },
    collection: {
      maxLength: {
        value: 32,
        message: t('validation.less_than', { 0: 32 }),
      },
    },
  };
};

export const macros = {
  name: {
    minLength: { value: 2, message: 'Should be at least 2 characters long' },
    maxLength: {
      value: LENGTH_XS,
      message: `Should be less or equal than ${LENGTH_XS} characters long`,
    },
    required: { value: true, message: 'This is a required field' },
  },
  text: {
    maxLength: {
      value: LENGTH_1024,
      message: `Should be less or equal than ${LENGTH_1024} characters long`,
    },
    required: { value: true, message: 'This is a required field' },
  },
};

export const ruleRules = {
  name: { required: true, maxLength: LENGTH_XS },
  description: { maxLength: LENGTH_M },
};

export const versionRules = {
  description: { maxLength: LENGTH_M },
};

export const profileRules = {
  name: { required: true, maxLength: LENGTH_S },
};

export const profileNameSchema = yup.object().shape({
  name: yup
    .string()
    .max(LENGTH_S)
    .test(
      'not-url',
      () => t('validation.url_not_allowed'),
      (value) => !/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(value)
    )
    .required(() => t('validation.required_field', { 0: t('common.name') })),
});

export const accountRules = {
  name: { required: true, maxLength: LENGTH_XS },
};

export const urlSchema = yup
  .string()
  .required(() => t('validation.required_field', { 0: 'Url' }))
  .max(LENGTH_XXXL)
  .test({
    test: (value) =>
      urlPattern.test(value) ||
      contextUrl.test(value) ||
      contextVariableWithBraces.test(value),
  });

export const webhookSchema = yup
  .object()
  .shape({
    name: yup.string().max(LENGTH_M).required(),
    url: yup.string().url().max(LENGTH_XXL).required(),
    token: yup.string().required(),
  })
  .required();

export const webhookRules = {
  name: { required: true, maxLength: LENGTH_M },
  url: { required: true, pattern: urlPattern, maxLength: LENGTH_XXL },
  token: { required: true },
};

export const intentRules = {
  name: { required: true, pattern: slug, minLength: 2, maxLength: LENGTH_S },
  description: { required: false, maxLength: LENGTH_M },
};

export const dialogRules = {
  name: { required: true, maxLength: MAX_DIALOG_NAME_LENGTH, minLength: 2 },
  description: { required: false, maxLength: LENGTH_M },
};

export const entityRules = {
  name: { required: true, pattern: slug, minLength: 2, maxLength: LENGTH_S },
  description: { required: false, maxLength: LENGTH_M },
};

export const bundleRules = {
  name: { required: true, maxLength: LENGTH_XS },
  description: { required: false, maxLength: LENGTH_M },
};

export const zendeskHandoverAction = {
  department_id: { required: true, maxLength: LENGTH_M },
};

export const handOverActionSchema = yup.object().shape({
  handover: yup.string(),
  department_id: yup.string().when('handover', (handover, schema) => {
    if (handover[0] === HANDOVER_OPTIONS.ZENDESK_DEPARTMENT_ROUTING) {
      return schema.max(LENGTH_M).required();
    }
    return schema.strip();
  }),
});

export const integrationRules = yup
  .object()
  .shape({
    name: yup.string().max(LENGTH_XS).required(),
  })
  .required();

export const integrationSessionTimeoutSchema = yup.object().shape({
  session_timeout: yup
    .number()
    .min(10, (v) => t('validation.minValue', { minValue: v.min.toString() }))
    .max(2880, (v) => t('validation.maxValue', { maxValue: v.max.toString() }))
    .typeError(() => t('validation.required'))
    .required(t('validation.required')),
});

export const integrationRulesFacebook = {
  domains: { required: false, maxLength: LENGTH_XL },
  accessToken: { required: true, maxLength: LENGTH_XL },
  verifyToken: { required: true, maxLength: LENGTH_XL },
  appSecret: { required: true, maxLength: LENGTH_XL },
  pageId: { required: true, maxLength: LENGTH_S },
  welcomeTriggerMessage: { required: false, maxLength: LENGTH_S },
  greetingText: { required: false, maxLength: LENGTH_M },
};

export const integrationRulesIntercom = {
  accessToken: { required: true, maxLength: LENGTH_XL },
  appId: { required: true, maxLength: LENGTH_S },
  welcomeTriggerMessage: { required: false, maxLength: LENGTH_S },
};

export const integrationRulesViber = {
  name: { required: true, maxLength: LENGTH_S },
  accessToken: { required: true, maxLength: LENGTH_XL },
  avatar: { required: true },
  welcomeTriggerMessage: { required: false, maxLength: LENGTH_S },
};

export const actionRules = {
  text: { required: true, maxLength: LENGTH_XXL },
};

export const nodeRules = {
  name: { required: true, maxLength: LENGTH_XS },
  intent: { pattern: slug, required: true },
};

export const MINIMUM_LANGUAGE_LENGTH = 2;

export const integrationRulesWebWidget = {
  domains: { required: false, maxLength: LENGTH_XL },
  welcomeTriggerMessage: { required: false, maxLength: LENGTH_S },
  welcomeMessage: { required: false, maxLength: LENGTH_S },
  widgetPosition: { required: false, maxLength: LENGTH_XXS },
  backgroundColor: { required: false, maxLength: LENGTH_XXS },
  accentColor: { required: false, maxLength: LENGTH_XXS },
  headerTitle: { required: false, maxLength: LENGTH_XXS },
  greeting: { maxLength: LENGTH_S },
  visitor_information: {
    fields: {
      language: {
        required: true,
        validate: {
          minLength: (v) => v.length >= MINIMUM_LANGUAGE_LENGTH,
        },
      },
    },
  },
};

export const suncoIntegration = {
  name: { required: false, maxLength: LENGTH_XS - 4 },
  welcome_trigger_message: { required: false, maxLength: LENGTH_S },
};

const zendeskParameter = yup.string().matches(slug).max(LENGTH_L);

export const zendeskOAuthSchema = yup.object().shape({
  company: yup.string().max(LENGTH_L),
  client_id: zendeskParameter,
  client_secret: zendeskParameter,
  subdomain: zendeskParameter,
});

export const requisiteSchema = yup.object().shape({
  check_for: yup
    .string()
    .matches(checkForPattern, { excludeEmptyString: true })
    .max(LENGTH_S),
  save_as: yup
    .string()
    .matches(saveAsRefRegExp, { excludeEmptyString: true })
    .max(LENGTH_S),
  if_not_present: yup.string().max(LENGTH_XXL),
});

export const requisiteRules = {
  check_for: {
    maxLength: LENGTH_S,
    pattern: checkForPattern,
  },
  save_as: { required: true, maxLength: LENGTH_S, pattern: saveAsRefRegExp },
  reprompt: {
    maxLength: LENGTH_XXL,
  },
  if_not_present: {
    maxLength: LENGTH_XXL,
  },
};

export const pauseActionRules = {
  show_typing: { required: true },
  duration: {
    required: true,
    validate: {
      maxValue: (v) => parseInt(v, 10) <= 10,
      minValue: (v) => parseInt(v, 10) >= 1,
    },
  },
};

export const urlActionSchema = yup.object().shape({
  url: yup.string().url().max(LENGTH_XXL).required(),
});

export const urlActionRules = {
  url: {
    required: true,
    maxLength: LENGTH_XXL,
    pattern: urlPattern,
  },
};

export const eventActionRules = {
  event: {
    validate: {
      required: (option) => !!option?.label,
    },
  },
};

const triggerNodeSchema = yup.lazy((value) =>
  typeof value === 'string'
    ? yup.string().required()
    : yup
        .object({
          dialog_name: yup.string(),
          value: yup.string(),
          label: yup.string(),
          node_type: yup.string(),
        })
        .required(t('validation.required'))
);

export const eventActionSchema = yup.object({
  trigger_node: triggerNodeSchema,
});

export const eventActionSchema2 = yup.object({
  jump_to: triggerNodeSchema,
});

export const webviewActionSchema = yup.object().shape({
  url: urlSchema,
  name: yup.string().required().max(MAX_DIALOG_NAME_LENGTH),
  label: yup.string().required().min(1).max(LENGTH_S),
  trigger_node_id: yup
    .mixed()
    .required()
    .notOneOf([''], () => {
      return i18n.t('validation.required_field', { 0: 'Trigger_node_id' });
    }) as yup.StringSchema<string>,
  height: yup.string().max(LENGTH_S),
});

export const webviewActionRules = {
  label: { required: true, maxLength: LENGTH_S },
  trigger_node_id: { maxLength: LENGTH_M },
  trigger_node_id_carousel: { required: true, maxLength: LENGTH_M },
  url: { required: true, maxLength: LENGTH_M, pattern: urlPattern },
  height: {},
  name: { required: true, maxLength: LENGTH_S },
};

export const imageActionSchema = yup.object().shape({
  url: yup.string().url().max(LENGTH_XXL).required(),
  name: yup.string().max(LENGTH_S).required(),
});

export const imageActionRules = {
  url: { required: true, maxLength: LENGTH_XXL, pattern: urlPattern },
  name: { required: true, maxLength: LENGTH_S },
};

export const audioActionSchema = imageActionSchema;

export const videoActionSchema = imageActionSchema;

export const fileActionSchemaNew = imageActionSchema;

// It is based on the regex slug, but allows empty strings
const tagPattern = /^([\w][\w-]*|)$/i;

export const TAG_LENGTH = 48;
export const newTag = yup.lazy((value) => {
  return typeof value === 'string'
    ? yup
        .string()
        .max(TAG_LENGTH)
        .matches(tagPattern, {
          message: () => t('dialog.tags.regex_error'),
        })
    : yup.object().nullable();
});

export const newTagSchema = yup.object({
  tag: newTag,
});

const zendeskTag = yup.string().matches(slug).max(48).required();

export const zendeskTagSchema = yup.object().shape({
  tag: zendeskTag,
});

export const fileActionSchema = yup.object().shape({
  url: yup.string().url().max(LENGTH_XXL).required(),
  size: yup.number().positive().integer(),
  name: yup.string().max(LENGTH_S).required(),
});

export const humanChatFieldsetDeskSchema = yup.object({
  tag: newTag,
  tags: yup.array().of(yup.string().max(26).required()),
  max_sessions_per_agent: yup
    .number()
    .transform((value, originalValue) =>
      String(originalValue).trim() === '' ? undefined : value
    )
    .nullable()
    .min(1, () => t('validation.min_value', { 0: 1 }))
    .max(100, () => t('validation.max_value', { 0: 100 })),
  is_service_desk: yup.boolean(),
});

export const fileActionRules = {
  url: {
    validate: (value: string) =>
      (urlPattern.test(value) || contextVariableWithBraces.test(value)) &&
      value.length < LENGTH_XXL,
  },
  size: { validate: { minValue: (v) => parseInt(v, 10) >= 10 } },
  name: { required: true, maxLength: LENGTH_S },
};

export const surveyActionRules = {
  url: {
    required: false,
    maxLength: LENGTH_XXXL,
    validate: {
      pattern: (v: string) =>
        contextUrl.test(v) || contextVariableWithBraces.test(v),
    },
  },
  name: {
    required: true,
    maxLength: MAX_DIALOG_NAME_LENGTH,
  },
  label: {
    required: true,
    maxLength: LENGTH_XS,
  },
};

export const surveyActionSchema = yup.object({
  survey_type: yup.string().required(),
  url: urlSchema,
  name: yup.string().required().max(MAX_DIALOG_NAME_LENGTH),
  label: yup.string().required().min(1).max(LENGTH_S),
  height: yup.string().max(LENGTH_S),
});

export const urlValidationSchema = yup.object().shape({
  url: urlSchema,
});

const sizePattern = /^(0?|[1-9][0-9]*)$/i;

export const generalRules = {
  url: {
    required: true,
    maxLength: LENGTH_XXXL,
    validate: {
      pattern: (v) => contextUrl.test(v) || contextVariableWithBraces.test(v),
    },
  },
  size: {
    maxLength: LENGTH_XS,
    validate: {
      pattern: (v: string) => sizePattern.test(v),
      minValue: (v: string) => parseInt(v) > 10,
    },
  },
  name: {
    required: false,
    maxLength: MAX_DIALOG_NAME_LENGTH,
  },
  label: {
    required: true,
    maxLength: LENGTH_XS,
  },
};

export const textSchema = yup.object().shape({
  texts: yup.array().of(yup.string().max(LENGTH_XXL)).min(1),
});

export const textRules = {
  text: {
    maxLength: LENGTH_XXL,
  },
};

export const noRequisiteTextSchema = yup.object().shape({
  text: yup.string().max(LENGTH_XXL),
});

export const noRequisiteTextRules = {
  text: { maxLength: LENGTH_XXL },
};

export const webhookToolkitSchema = yup.object().shape({
  fallback: yup.string().max(LENGTH_XXL),
  webhook_id: yup.string().required(),
});

export const webhookToolkitRules = {
  fallback: {
    maxLength: LENGTH_XXL,
  },
  webhook_id: { required: true },
};

export const urlRules = {
  url: { required: true, pattern: urlPattern, maxLength: LENGTH_XXL },
};

export const urlRulesWithContextVariables = {
  url: {
    required: true,
    maxLength: LENGTH_XXL,
    validate: {
      urlPattern: (value) =>
        urlPattern.test(value) ||
        contextUrl.test(value) ||
        contextVariableWithBraces.test(value) ||
        t('errors.invalid_url'),
    },
  },
};

export const textActionSchema = yup.object().shape({
  texts: yup.array().of(yup.string().max(LENGTH_XXL)).min(1),
});

export const optionRules = {
  text: { required: true, maxLength: LENGTH_XL },
  label: { required: true, maxLength: LENGTH_S },
};

export const optionSchema = yup.object().shape({
  text: yup.string().max(LENGTH_XL).required(),
  label: yup.string().max(LENGTH_S).required(),
});

export const variableSchema = yup.object().shape({
  name: yup.string().max(LENGTH_S).required(),
});

export const variableRules = {
  variable: {
    validate: {
      required: (option) => !!option?.label,
      maxLength: (option) => option?.label?.length <= LENGTH_S,
    },
  },
};

export const setVariableSchema = yup.object().shape({
  variable: yup
    .object({
      label: yup.string().max(LENGTH_L),
      value: yup
        .string()
        .matches(saveAsRefRegExp, {
          message: () => i18n.t('validation.pattern'),
        })
        .max(LENGTH_L)
        .required(),
      type: yup.string(),
    })
    .required(),
  value: yup.string().when('variable', (variable, schema) => {
    // Increase the max length for live_instructions
    if (variable[0]?.value === 'live_instructions') {
      return schema.min(1).max(LENGTH_XXXL).required();
    }
    return schema.min(1).max(LENGTH_L).required();
  }),
});

export const resetActionSchema = yup.object().shape({
  variable: yup
    .object({
      value: yup.string().max(LENGTH_L),
      label: yup
        .string()
        .max(LENGTH_L)
        .test(
          'empty-or-matches-pattern',
          () => i18n.t('validation.pattern'),
          function (value) {
            // Only enforce the regex pattern if the string is not empty
            return !value || contextRefRegExp.test(value);
          }
        ),
    })
    .nullable(),
});

export const nameSchema = yup.object().shape({
  name: yup.string().max(MAX_DIALOG_NAME_LENGTH).required(),
});

export const nameRules = {
  name: {
    required: true,
    maxLength: LENGTH_S,
  },
};

export const nodeSchema = yup.object().shape({
  name: yup.string().max(LENGTH_S).required(),
  intent: yup.string().matches(slug),
});

const name = yup.string().min(1).max(MAX_DIALOG_NAME_LENGTH);
const intent = yup.string().min(2).max(LENGTH_S).matches(slug);

export const intentFormSchema = yup.object().shape({
  name: name.required(),
  intent: yup
    .object()
    .shape({
      label: yup
        .string()
        .min(2)
        .max(LENGTH_S + 1),
      value: intent,
    })
    .required(),
  disambiguation: yup.boolean().required(),
  disambiguation_label: yup
    .string()
    .when('disambiguation', (disambiguation, schema) => {
      if (disambiguation) {
        return schema.min(1).max(45).required();
      }
      return schema.strip();
    }),
});

export const requireValueInRule = [
  'equal',
  'not_equal',
  'greater',
  'less',
  'contain',
  'not_contain',
];

export const conditionRuleSchema = yup.object().shape({
  name: yup
    .string()
    .matches(checkForPattern, { excludeEmptyString: true })
    .max(LENGTH_S)
    .required(),
  value: yup.string().max(LENGTH_M),
  operator: yup
    .string()
    .oneOf(['exist', 'not_exist', ...requireValueInRule])
    .required(),
});

export const conditionRuleRules = {
  name: { required: true, maxLength: LENGTH_S, pattern: checkForPattern },
  value: { required: true, maxLength: LENGTH_M },
  value_auto: {
    required: true,
    validate: { maxLength: (option) => option?.label?.length <= LENGTH_M },
  },
  operator: {
    required: true,
    validate: {
      oneOf: (v) => ['exist', 'not_exist', ...requireValueInRule].includes(v),
    },
  },
};

export const conditionSchema = yup.object().shape({
  name: yup.string().max(MAX_DIALOG_NAME_LENGTH).required(),
  match: yup.string().oneOf(['all', 'any', 'else']),
  rules: yup.array().of(conditionRuleSchema),
});

export const newConditionSchema = yup.object().shape({
  name: yup.string().max(MAX_DIALOG_NAME_LENGTH).required(),
  match: yup.string().oneOf(['all', 'any', 'else', 'equals']),
});

export const invitationSchema = yup.object().shape({
  name: yup.string().max(LENGTH_S).required(),
  email: yup.string().email().required(),
  role: yup.string().required(),
});

export const replayActionSchema = yup.object().shape({
  replay: yup.string().required(),
  customMessage: yup.string().when('replay', (replay, schema) => {
    if (replay[0] === 'default') {
      return schema.strip();
    }
    return schema.min(1).max(LENGTH_1024).required();
  }),
});

export const oldInvitationRules = {
  name: { required: true, maxLength: LENGTH_S },
  email: { required: true, pattern: emailPattern },
  role: { required: true },
};

export const invitationRules = (t: TFunction) => ({
  emails: {
    validate: {
      minLength: (value) => {
        return value.length > 0 || t('validation.required');
      },
      emails: (value) => {
        const invalidEmails = value.reduce((acc, email) => {
          if (!emailPattern.test(email)) {
            acc.push(email);
          }
          return acc;
        }, []);
        return (
          invalidEmails.length === 0 ||
          `${t('errors.invalid_email')}: ${invalidEmails.join(', ')}`
        );
      },
    } as Record<string, Validate<string[], unknown>>,
  },
  roles: {
    validate: {
      minLength: (value) => {
        return value.length !== 0 || t('validation.required');
      },
    },
  },
});

export const removeEmptyFields = <T>(data: T): T => {
  return omitBy(
    data || {},
    (v) => v === '' || typeof v === 'undefined'
  ) as unknown as T;
};

export const isRequired = {
  required: { value: true, message: 'This is a required field' },
};

export const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const visitorEmail = yup
  .object()
  .shape({
    email: yup.string().email().required(),
  })
  .required();

export const visitorDisplayName = yup
  .object()
  .shape({
    display_name: yup.string().max(LENGTH_XS).required(),
  })
  .required();

export const visitorAddress = yup.object().shape({
  address: yup.string().max(LENGTH_M).min(5).required(),
});

const phoneRegExp = /^(\+?)(?:[0-9]?){6,15}[0-9]$/;
export const phoneRegExpInline = /(\+?)(?:[0-9]?){6,15}[0-9]/;

export const visitorPhone = yup.object().shape({
  phone: yup
    .string()
    .matches(phoneRegExp, () => t('contacts.errors.phone'))
    .required(),
});
export const phoneCarouselButton = {
  phone: { required: true, pattern: phoneRegExp },
};

export const customerRsaKey = { required: true, maxLength: LENGTH_XXXL / 2 };

export const welcomeTriggerMessageValidator = { maxLength: LENGTH_S };
export const headlineValidator = { maxLength: LENGTH_M };

/*
 * Matching examples:
 * $variable
 * $variable.secondPart
 * $variable.secondPart.thirdPart
 * @contextName
 * @context-name
 */
export const entityOrContext = new RegExp(
  /^\$[\w][\w-]*(?:\.[\w-]+)*$|^@[\w][\w-]*$/i
);

const emailBodySchema = yup
  .string()
  .min(1, t('validation.required_field', { 0: t('dialog.email.body') }))
  .max(LENGTH_1024 * 2, t('rich_text_editor.errors.char_limit'));

export const recipientSchema = yup
  .string()
  .test({
    message: () => t('dialog.errors.email_recipient'),
    test: (value) =>
      value === '' ||
      value === null ||
      entityOrContext.test(value) ||
      emailPattern.test(value),
  })
  .nullable()
  .defined(() => t('dialog.errors.email_recipient'));

export const emailToolkitSchema = yup.object().shape({
  subject: yup.string().max(LENGTH_S).required(),
  body: emailBodySchema,
  to: yup
    .array()
    .min(1, () =>
      t('dialog.errors.at_least_one', { 0: t('dialog.email.recipient') })
    )
    .max(MAX_EMAIL_RECIPIENTS),
  recipient: yup
    .object()
    .nullable()
    .defined(() => t('dialog.errors.email_recipient')),
});

export const emailToolkitRules = {
  from: { required: true, pattern: emailPattern, maxLength: LENGTH_S },
  recipient: {
    validate: {
      maxLength: (value) => {
        if (typeof value === 'string') {
          return value.length <= LENGTH_S;
        }
        return value?.label?.length <= LENGTH_S;
      },
      pattern: (value) => {
        if (value === '') {
          return true;
        }
        if (typeof value === 'string') {
          return emailPattern.test(value) || entityOrContext.test(value);
        }

        return (
          emailPattern.test(value?.label) || entityOrContext.test(value?.label)
        );
      },
    },
  },
  subject: {
    required: true,
    maxLength: LENGTH_S,
  },
  body: {
    maxLength: LENGTH_XXL,
  },
};

export const googleSheetToolkitRules = {
  spreadsheet_id: {
    pattern: spreadsheetIdPattern,
    required: true,
    maxLength: LENGTH_S,
  },
  sheet_id: { required: true, maxLength: LENGTH_S },
  input: {
    validate: {
      required: (value) => !!value?.label && value?.label != '',
      maxLength: (value) => value?.label?.length <= LENGTH_S,
      pattern: (value) => entityOrContext.test(value?.label),
    },
  },
};

const spreadsheetIdRegExp = spreadsheetIdPattern;
const spreadsheetId = yup
  .string()
  .max(64)
  .matches(spreadsheetIdRegExp, {
    message: () => i18n.t('actions.googlesheet.regex_error_spreadsheet_id'),
  });

export const contextVariableValueSchema = yup
  .string()
  .max(LENGTH_L)
  .test(
    'context-variable-value',
    () => t('validation.pattern'),
    (value) => {
      if (value === '') {
        return true;
      }

      return contextRefRegExp.test(value);
    }
  );

export const googleSheetToolkitSchema = yup.object().shape({
  spreadsheet_id: spreadsheetId.required(),
  sheet_id: yup.string().max(64).required(),
  input: yup
    .object()
    .shape({
      label: yup.string().max(LENGTH_L),
      value: contextVariableValueSchema,
      type: yup.string(),
    })
    .nullable(),
});

export const whatsappIntegrationConfig = {
  access_token: { required: true, maxLength: LENGTH_1024 },
  verify_token: { required: true, maxLength: LENGTH_1024 },
  app_secret: { required: true, maxLength: LENGTH_1024 },
  phone_number_id: { required: true, maxLength: LENGTH_1024 },
  prefilled_message: { required: true, maxLength: LENGTH_M },
};

export const whatsappTestNumber = {
  label: { required: true, maxLength: LENGTH_XS },
  phone_number: { required: true, slug: phoneRegExp },
  desk_id: { required: true },
};

export const frontIntegrationConfig = {
  teammate_id: { required: true },
  sender_name: { required: true },
};

export const requiredValidation = <T>(
  v: PathValue<T, Path<T>>,
  t: TFunction,
  shouldValidate: boolean
) => {
  if (!shouldValidate) {
    return true;
  }
  const chars = getCharCount(v);
  if (chars === 0) {
    return t('validation.required');
  }
  return true;
};

export const charCountValidation = <T>(
  v: PathValue<T, Path<T>>,
  maxCharacters: number,
  t: TFunction,
  shouldValidate: boolean
) => {
  if (!shouldValidate) {
    return true;
  }
  const chars = getCharCount(v);
  if (chars > maxCharacters) {
    return t('rich_text_editor.errors.char_limit');
  }
  return true;
};

export const toolkitQuestionSchema = yup.object().shape({
  questionType: yup.string().oneOf(['ask', 'check_ask']),
  save_as: yup
    .object({
      value: contextRefSchema.min(3).max(LENGTH_L),
      label: yup.string(),
      type: yup.string(),
    })
    .required(),
});

export const toolkitConditionRuleSchema = yup.object().shape({
  name: yup
    .object()
    .shape({
      type: yup.string(),
      label: yup.string(),
      value: yup
        .string()
        .matches(entityOrContext, {
          message: () => t('validation.pattern'),
        })
        .min(2)
        .max(LENGTH_L)
        .required(),
    })
    .required(() =>
      t('validation.required_field', {
        0: `${t('common.rule')} ${t('common.name').toLowerCase()}`,
      })
    ),
  value_auto: yup
    .object()
    .shape({
      type: yup.string(),
      label: yup.string(),
      value: yup.string().max(LENGTH_M).required(),
    })
    .required(() =>
      t('validation.required_field', {
        0: `${t('common.rule')} ${t('dialog.value').toLowerCase()}`,
      })
    ),
});

export const getRichTextEditorTabSchema = (minLength = 1, maxLength: number) =>
  yup.array().of(
    yup.object({
      children: yup.array().of(
        yup.object({
          text: yup
            .string()
            .min(minLength, () =>
              t('validation.required_field', { 0: t('dialog.email.body') })
            )
            .max(maxLength, () => t('rich_text_editor.errors.char_limit')),
          type: yup.string(),
          children: yup.array().of(
            yup.object({
              text: yup
                .string()
                .min(minLength, () =>
                  t('validation.required_field', { 0: t('dialog.email.body') })
                )
                .max(maxLength, () => t('rich_text_editor.errors.char_limit')),
            })
          ),
        })
      ),
    })
  );

export const askOnlySchema = yup.object().shape({
  ask_only: yup
    .string()
    .trim()
    .min(1, t('validation.required_field', { 0: t('dialog.email.body') }))
    .max(LENGTH_XXL, t('rich_text_editor.errors.char_limit')),
});

export const checkForSchema = yup.object().shape({
  check_for: yup.object().required(),
  tabs: yup.array().of(
    yup.object({
      tabName: yup.string().required(),
      maxCharacters: yup.number().required(),
      textValue: getRichTextEditorTabSchema(0, LENGTH_XXL),
    })
  ),
});

export const requisiteOptionSchema = yup.object().shape({
  text: yup.string().max(LENGTH_XL).required(),
  label: yup.string().max(LENGTH_S).required(),
  option_id: yup.string().required(),
});

const CAROUSEL_SUBTITLE_MAX_LENGTH = 80;

export const carouselCardSchema = yup.object().shape({
  title: yup
    .string()
    .max(LENGTH_S, () => t('validation.less_than', { 0: LENGTH_S }))
    .required(() => t('validation.required_field', { 0: t('common.title') })),
  subtitle: yup
    .string()
    .max(CAROUSEL_SUBTITLE_MAX_LENGTH, () =>
      t('validation.less_than', { 0: CAROUSEL_SUBTITLE_MAX_LENGTH })
    ),
  media: yup.object().shape({
    url: urlSchema,
    type: yup.string().oneOf(['image', 'video']),
  }),
});

export const carouselSchema = yup.object().shape({
  carousel: yup.array().of(carouselCardSchema),
});

export const aiProfile = (maxLength: number) => {
  return yup.object({
    name: yup.string().max(32), // Prevent the user from doing prompt injection
    response_size: yup.string().oneOf(['short', 'long']),
    humor_level: yup.number(),
    formality_level: yup.number(),
    support_handover: yup.bool(),
    respectfulness_level: yup.number(),
    creativity_level: yup.number(),
    custom_instructions: yup
      .string()
      .max(maxLength, () => t('validation.max_value', { 0: maxLength })),
  });
};

const defaultCarouselButtonSelectOptions = defaultActionOptions.map(
  (option) => option.value
);

export const carouselButtonSchema = (duplicatedLabels: boolean) =>
  yup.object({
    label: yup
      .string()
      .min(1)
      .max(32)
      .required()
      .test(
        'isDuplicated',
        t('dialog.errors.carousel_duplicated_button_label'),
        () => !duplicatedLabels
      ),

    type: yup
      .string()
      .oneOf([...defaultCarouselButtonSelectOptions])
      .required(),
    value: yup.string().when('type', ([type], schema) => {
      // If the type is postback or phone
      if (
        type === defaultCarouselButtonSelectOptions[1] ||
        type === defaultCarouselButtonSelectOptions[3]
      ) {
        return schema.max(LENGTH_M).required();
      }
      return schema.strip();
    }),
    url: yup.string().when('type', ([type], schema) => {
      // If the type is url or webview
      if (
        type === defaultCarouselButtonSelectOptions[0] ||
        type === defaultCarouselButtonSelectOptions[2]
      ) {
        return urlSchema;
      }
      return schema.strip();
    }),
    height: yup.string().when('type', ([type], schema) => {
      // If the type is webview
      if (type === defaultCarouselButtonSelectOptions[2]) {
        return yup.string().max(LENGTH_S).required();
      }
      return schema.strip();
    }),
    trigger_node_id: yup.string().when('type', ([type], schema) => {
      // If the type is webview
      if (type === defaultCarouselButtonSelectOptions[2]) {
        return yup.string().min(2).max(LENGTH_S).matches(slug).required();
      }
      return schema.strip();
    }),
  });

// Schema generator for the EditableText input fields
export const getEditableTextValidationSchema = (
  maxLength: number,
  restrictedValues: Array<string>,
  label: string,
  patternValidation?: RegExp // New parameter to control regex validation
) => {
  let schema = yup
    .string()
    .trim()
    .required()
    .min(2)
    .max(maxLength)
    .test('unique-name', t('validation.name_already_exists'), function (value) {
      if (!value || !restrictedValues.includes(value.toLowerCase())) {
        return true;
      }
      return false;
    })
    .label(label);

  // Conditionally add the regex validation
  if (patternValidation) {
    schema = schema.matches(
      patternValidation,
      t('broadcasts.invalid_characters')
    );
  }

  return yup.object().shape({
    inputField: schema,
  });
};

const intentNameSchema = yup
  .string()
  // Remove the # from the beginning of the string before validating for slug pattern
  .transform((value) => (value?.startsWith('#') ? value.substring(1) : value))
  .required()
  .matches(slug, {
    message: t('errors.invalid_intent'),
  });

export const intentSchema = (label: string) =>
  yup.object({
    intent: yup
      .object()
      .shape({
        label: intentNameSchema,
        value: intentNameSchema,
      })
      .required(),
    expressions: yup.array().of(
      yup.object().shape({
        name: yup.string().nullable().max(512).label(label),
      })
    ),
  });

export const createInputSchema = (maxLength: number) => ({
  required: t('validation.required'),
  maxLength: {
    value: maxLength,
    message: t('validation.less_than', { 0: maxLength }),
  },
  validate: {
    noUrl: (value) => {
      return !noUrlPattern.test(value) || t('validation.url_not_allowed');
    },
  },
});
