import { t } from 'i18next';

import Brain from '@/components/atoms/Icons/Brain';
import Collections from '@/components/atoms/Icons/Collections';
import Integrations from '@/components/atoms/Icons/Integrations';
import Analytics from '@/components/atoms/Icons/Navigation/Analytics';
import Assist from '@/components/atoms/Icons/Navigation/Assist';
import Brand from '@/components/atoms/Icons/Navigation/Brand';
import Build from '@/components/atoms/Icons/Navigation/Build';
import Deploy from '@/components/atoms/Icons/Navigation/Deploy';
import Rules from '@/components/atoms/Icons/Rules';
import { IconBroadcast } from '@/modules/broadcast/icons/Broadcast';
import { DeveloperToolsIcon } from '@/modules/developerTools/icons/DeveloperToolsIcon';

const ICON_COLOR = 'var(--icon-default-gray)';

export const navigationItems = (deskId: string) => [
  {
    icon: <Brand color="var(--icon-default-white)" />,
    link: '/',
    i18nKey: 'main_navigation.home',
  },
  {
    icon: <Build size={32} />,
    i18nKey: 'main_navigation.build',
    submenuItems: [
      {
        icon: <Brain color={ICON_COLOR} />,
        title: t('common.brains'),
        link: '/brains',
      },
      {
        icon: <Collections color={ICON_COLOR} />,
        title: t('main_navigation.collections'),
        link: '/collections',
      },
      {
        icon: <IconBroadcast color={ICON_COLOR} />,
        title: t('common.broadcasts'),
        link: '/broadcasts',
      },
    ],
  },
  {
    icon: <Deploy />,
    i18nKey: 'main_navigation.deploy',
    submenuItems: [
      {
        icon: <Integrations color={ICON_COLOR} />,
        title: t('main_navigation.integrations'),
        link: deskId ? `/environments/${deskId}/integrations` : '/integrations',
      },
      {
        icon: <Rules color={ICON_COLOR} />,
        title: t('main_navigation.rules'),
        link: deskId ? `/environments/${deskId}/rules` : '/rules',
      },
      {
        icon: <DeveloperToolsIcon color={ICON_COLOR} />,
        title: t('developer_tools.title'),
        link: '/devtools',
      },
    ],
  },
  {
    icon: <Analytics color="var(--icon-default-white)" />,
    link: '/analytics',
    i18nKey: 'main_navigation.improve',
  },
  {
    icon: <Assist color="var(--icon-default-white)" />,
    link: '/chats',
    i18nKey: 'main_navigation.assist',
  },
];
