/* eslint-disable react/no-array-index-key */
import React, {
  ReactElement,
  ReactFragment,
  ReactText,
  useCallback,
  useState,
} from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';
import {
  useTable,
  useRowSelect,
  useGlobalFilter,
  useSortBy,
  UseTableColumnOptions,
  CellProps,
  useFlexLayout,
} from 'react-table';

import FiltersIcon from '@/components/atoms/Icons/Filters';
import { OptionBase } from '@/models/common';
import { noop } from '@/util/util';

import { TableAPIFilter } from './TableAPIfilter';
import { TableCheckbox } from './TableCheckbox';
import { TableFilter } from './TableFilter';
import { TableSelection } from './TableSelection';
import DropdownList from '../DropdownList/DropdownList';
import SortIcon from '../Icons/SortIcon';

import styles from './Table.module.scss';

// FIXME: react-table v8 will be written in typescript but for now we need to
// fix typing errors with manual types
// See https://github.com/tannerlinsley/react-table/issues/3064#issuecomment-786340487
interface Column<T extends {}> extends UseTableColumnOptions<T> {
  Cell?: (props: CellProps<T>) => ReactElement | ReactText | ReactFragment;
}

type TableProps<T extends {}> = {
  columns: Column<T>[];
  data: T[];
  selectable?: boolean;
  checkable?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  emptyMessage?: string;
  overflown?: boolean;
  noGutters?: boolean;
  fullWidth?: boolean;
  filterableIcon?: boolean;
  searchable?: boolean;
  onSelectionClick?: (row) => void;
  sortBy?: string;
  filterOptions?: OptionBase[];
  onFilterChange?: (val: number | string) => void;
  flexLayout?: boolean;
  searchPlaceholder?: string;
  variant?: 'light' | 'dark';
  headerHeight?: 'small' | 'medium';
  hideHeader?: boolean;
  title?: string;
  onAPISearch?: (search: string) => void;
  loading?: boolean;
  defaultSearchValue?: string;
};

const Table = <T extends {}>({
  columns,
  data,
  selectable = false,
  checkable = false,
  filterable = false,
  sortable,
  searchable = true,
  emptyMessage = '',
  filterableIcon = true,
  fullWidth = true,
  overflown,
  noGutters,
  onSelectionClick = noop,
  sortBy,
  filterOptions,
  onFilterChange,
  flexLayout = false,
  searchPlaceholder,
  variant = 'light',
  headerHeight = 'small',
  hideHeader = false,
  title,
  loading,
  onAPISearch,
  defaultSearchValue,
}: TableProps<T>) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(0);

  const handleOptionClick = useCallback(
    (item) => {
      const index = filterOptions.findIndex((f) => f.value === item.value);
      setSelected(index);
      onFilterChange(item.value);
    },
    [filterOptions, onFilterChange]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    toggleAllRowsSelected,
    toggleRowSelected,
  } = useTable(
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      columns: columns as any,
      data,
      disableSortRemove: true,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: sortBy,
            desc:
              sortBy === 'last_used' ||
              sortBy === 'time' ||
              sortBy === 'updated',
          },
        ],
      },
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          let tempRow1 = row1.values[columnName];
          let tempRow2 = row2.values[columnName];

          if (typeof tempRow1 === 'string') {
            tempRow1 = tempRow1.toLowerCase();
          }
          if (typeof tempRow2 === 'string') {
            tempRow2 = tempRow2.toLowerCase();
          }

          return tempRow1 > tempRow2 ? 1 : -1;
        },
      },
    },
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    flexLayout && useFlexLayout,
    (hooks) => {
      if (checkable) {
        hooks.visibleColumns.push((cols) => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <TableCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <TableCheckbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...cols,
        ]);
      } else if (selectable) {
        hooks.visibleColumns.push((cols) => [
          {
            id: 'selection',
            Header: '',
            Cell: ({ row }) => (
              <TableSelection
                {...row.getToggleRowSelectedProps()}
                onClick={() => {
                  toggleAllRowsSelected(false);
                  toggleRowSelected(row.id, true);
                  onSelectionClick(row.original as T);
                }}
              />
            ),
          },
          ...cols,
        ]);
      }
    }
  );
  return (
    <>
      <div
        className={cn(
          styles.container,
          'tableContainer',
          styles[variant],
          styles[`headerHeight--${headerHeight}`],
          {
            [styles.fullWidth]: fullWidth,
            [styles.overflown]: overflown,
            [styles.noGutters]: noGutters,
          }
        )}
      >
        {title && (
          <Typography
            variant="heading2-bold"
            color="var(--text-default-gray)"
            mb="var(--space-24)"
            component="h2"
          >
            {title}
          </Typography>
        )}
        <table {...getTableProps()}>
          {!hideHeader && (
            <thead>
              {filterable && (
                <tr>
                  <th
                    colSpan={visibleColumns?.length}
                    className={styles.filterRow}
                  >
                    <div
                      className={cn(styles.filterContainer, {
                        [styles.flexLayout]: flexLayout,
                      })}
                    >
                      {searchable && (
                        <TableFilter
                          preGlobalFilteredRows={preGlobalFilteredRows}
                          globalFilter={state.globalFilter}
                          setGlobalFilter={setGlobalFilter}
                          searchPlaceholder={searchPlaceholder}
                        />
                      )}

                      {onAPISearch && (
                        <TableAPIFilter
                          onChange={onAPISearch}
                          searchPlaceholder={searchPlaceholder}
                          defaultValue={defaultSearchValue}
                        />
                      )}
                      {!searchable && !onAPISearch && <div />}
                      {filterOptions && (
                        <DropdownList
                          options={filterOptions}
                          fullWidth
                          size="large"
                          height="large"
                          optionClick={handleOptionClick}
                          selected={filterOptions[selected]?.value}
                          alignment="right"
                        >
                          <div className={styles.filterButton}>
                            {filterableIcon && <FiltersIcon />}
                            <span>{filterOptions[selected]?.label}</span>
                          </div>
                        </DropdownList>
                      )}
                    </div>
                  </th>
                </tr>
              )}
              {headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                  {headerGroup.headers.map((column, index) => {
                    const headerPropsWithouKey = omit(
                      column.getHeaderProps(column.getSortByToggleProps()),
                      'key'
                    );
                    return (
                      <React.Fragment key={index}>
                        {sortable && column.canSort ? (
                          <th
                            {...headerPropsWithouKey}
                            style={{
                              position: 'sticky',
                              left: 0,
                            }}
                          >
                            <div className={styles.headerItem}>
                              <span>{column.render('Header')}</span>
                              <span>
                                <SortIcon
                                  color={
                                    column.isSorted
                                      ? 'var(--icon-default-blue)'
                                      : undefined
                                  }
                                  rotate={
                                    column.isSorted && !column.isSortedDesc
                                  }
                                />
                              </span>
                            </div>
                          </th>
                        ) : (
                          <>
                            <th
                              {...column.getHeaderProps()}
                              key={column.getHeaderProps().key}
                            >
                              {column.render('Header')}
                            </th>
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tr>
              ))}
            </thead>
          )}
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        // Pass key directly to avoid warning
                        key={cell.getCellProps().key}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {!loading && rows.length === 0 && (
          <div className={styles.empty}>{emptyMessage || t('rules.empty')}</div>
        )}

        {loading && (
          <div className={styles.loading}>
            <CircularProgress size={40} />
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(Table);
