import Typography from '@mui/material/Typography';
import cn from 'classnames';
import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';

import styles from './SidebarFields.module.scss';

interface Props {
  validSubscribersCount: number;
  errorsCount: number;
  contextVariablesCount?: number;
}
const SidebarFields = ({
  validSubscribersCount = 0,
  errorsCount = 0,
  contextVariablesCount,
}: Props) => {
  const { t } = useTranslation();

  const filesData = [
    {
      label: t('broadcasts.recipients'),
      value: validSubscribersCount,
    },
    {
      label: t('common.variables'),
      value: contextVariablesCount ?? 0,
    },
    {
      label: t('common.errors'),
      value: errorsCount,
    },
  ];

  return (
    <div
      className={cn(styles.sidebarHeader, {
        [styles.hasContextVariables]: !isNil(contextVariablesCount),
      })}
    >
      {filesData.map((item) => {
        if (
          item.label === t('common.variables') &&
          isNil(contextVariablesCount)
        ) {
          return null;
        }
        return (
          <div className={styles.box} key={item.label}>
            <Typography
              variant="label-caps-big"
              color="var(--text-default-gray)"
            >
              {item.label}
            </Typography>
            <Typography
              variant="heading1-bold"
              color="var(--text-default-black)"
            >
              {isNaN(item.value) ? 0 : item.value}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default SidebarFields;
