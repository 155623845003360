import { useCallback, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import uniq from 'lodash/uniq';
import { Resolver, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Option } from '@/components/atoms/AutoComplete/AutoComplete';
import AutocompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import Button from '@/components/atoms/Button/Button/Button';
import IconButton from '@/components/atoms/IconButton/IconButton';
import CloseIcon from '@/components/atoms/Icons/Close';
import Inputs from '@/components/atoms/Inputs/Inputs';
import useIntents from '@/hooks/useIntents';
import { Intent } from '@/models/intent';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { popModal } from '@/redux/modals/actions';
import { selectAccountSlug } from '@/redux/session/selectors';
import { capitalizeFirstLetter } from '@/util/util';
import { intentSchema } from '@/util/validator';

import Modal from '../../Modals/Modal';
import { convertSpacesToUnderscores } from '../../SubHeader/helper';

import styles from './IntentModal.module.scss';

type Props = {
  brainId: string;
  expression: string;
  close: () => void;
  intents?: { intent: string; confidence: number }[];
};

const removeHash = (value: Option | null) => value?.label.replace('#', '');
const addhHash = (inputString: string): string => {
  if (inputString.startsWith('#')) {
    return inputString;
  } else {
    return '#' + inputString;
  }
};

const formatIntentOption = (option: Option) => ({
  ...option,
  label: addhHash(convertSpacesToUnderscores(option.label)),
  value: convertSpacesToUnderscores(option.value),
});

type FormValues = {
  intent: Option;
  expressions: {
    name: string;
  }[];
};

const IntentModal = ({ close, brainId, expression, intents }: Props) => {
  // Redux
  const accountSlug = useSelector(selectAccountSlug);

  // Custom hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    intents: hookIntents,
    updateIntent,
    createNewIntent,
  } = useIntents(brainId);

  // RHF
  const {
    handleSubmit,
    control,
    resetField,
    formState: { errors, isValid },
    register,
    setValue,
    setError,
  } = useForm<FormValues>({
    defaultValues: {
      intent: null,
      expressions: [{ name: expression }],
    },
    resolver: yupResolver(
      intentSchema(t('try_it.details.training_phrase'))
    ) as Resolver<FormValues>,
  });

  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'expressions',
  });

  const values = useWatch({
    control,
    name: 'expressions',
  });

  // Local variables
  // If intents are passed, we use them, otherwise we use the hook intents
  const finalIntents = intents || hookIntents;

  const options: Option[] = useMemo(
    () =>
      finalIntents?.map((intent) => ({
        label: `#${intent.intent}`,
        value: `${intent.intent}`,
        type: 'intent',
      })),
    [finalIntents]
  );

  // Handlers
  const onSubmit = useCallback(
    async (data: FormValues) => {
      const intentName = removeHash(data.intent) as string;

      // We use hookIntents because its entries have the expressions property
      const existingIntent = hookIntents?.find((i) => i.intent === intentName);
      const trainingPhrases = data.expressions
        .map((expression) => expression.name)
        .filter((expression) => expression !== '' && expression !== null);

      // Prevent submitting form with empty training phrases
      if (trainingPhrases.length === 0) {
        setError('expressions.0.name', {
          type: 'required',
          message: t('common.required_field'),
        });

        return;
      }

      if (existingIntent) {
        // Remove duplicates
        const uniquePhrases = uniq([
          ...existingIntent.expressions,
          ...trainingPhrases,
        ]);
        updateIntent({
          name: existingIntent.intent,
          intent: {
            expressions: uniquePhrases,
          },
        });
      } else {
        const newIntent: Partial<Intent> = {
          intent: intentName,
          collection: '',
          expressions: trainingPhrases,
        };
        const result = await createNewIntent(newIntent);
        dispatch(
          addTemporalToast(
            'success',
            t('intent.intent_created', { 0: result?.intent })
          )
        );
        dispatch(popModal());
        navigate(`/${accountSlug}/brains/${brainId}/intents/${result?.intent}`);
      }

      // Reset
      resetField('intent');
      close();
    },
    [
      hookIntents,
      resetField,
      close,
      setError,
      t,
      updateIntent,
      createNewIntent,
      dispatch,
      navigate,
      accountSlug,
      brainId,
    ]
  );

  const handleChange = (_, option: Option | null) => {
    if (!option) return;

    const sanitizedIntent = formatIntentOption(option);

    setValue('intent', sanitizedIntent, { shouldValidate: true });
  };

  const customSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (values[0].name === '') {
      setValue('expressions.0.name', null);
    }

    await handleSubmit(onSubmit)();
  };

  return (
    <Modal noGutters cleanModal onBlur={close} isLayeredAbove>
      <form className={styles.form}>
        <div className={styles.header}>
          <Typography variant="heading2-medium" component="h2">
            {t('try_it.details.add_training_phrase')}
          </Typography>

          <IconButton onClick={close} ariaLabel={t('modals.close')}>
            <CloseIcon size={20} />
          </IconButton>
        </div>

        <AutocompleteNew
          label={t('common.intents')}
          control={control}
          options={options}
          name="intent"
          placeholder={t('try_it.select_intent')}
          hasError={!!errors?.intent}
          errorMessage={capitalizeFirstLetter(
            errors?.intent?.message || errors?.intent?.label.message
          )}
          width={418}
          enableNewEntry
          groupByLabelProp={false}
          groupByProp="type"
          freeSolo
          onChange={handleChange}
        />

        <div className={styles.scrollable}>
          <div className={styles.inputsWrapper}>
            <Typography
              variant="label-caps-big"
              component="h3"
              color="var(--text-default-gray)"
              mb="var(--space-8)"
            >
              {t('training_phrases.subtitle')}
            </Typography>

            <Inputs
              fields={fields}
              errors={errors.expressions}
              register={register}
              values={values}
              onCreate={() => prepend({ name: '' })}
              onDelete={(index) => remove(index)}
              namePrefix="expressions"
              placeholder={t('training_phrases.placeholder')}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();

                  if (
                    (e.target as HTMLInputElement).value !== '' &&
                    (e.target as HTMLInputElement).name === 'expressions.0.name'
                  ) {
                    prepend({ name: '' });
                  }
                }
              }}
            />
          </div>
        </div>

        <footer className={styles.footer}>
          <Button variant="secondary" onClick={close}>
            {t('common.cancel')}
          </Button>
          <Button
            disabled={!isValid}
            type="submit"
            variant="primary"
            onClick={customSubmit}
          >
            {t('common.add')}
          </Button>
        </footer>
      </form>
    </Modal>
  );
};

export default IntentModal;
