import { useCallback, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { MODAL_TRYIT } from '@/components/organisms/Modals/ModalConductor';
import { useAccount } from '@/hooks/useAccount';
import { NavigationItem } from '@/models/tryIt';
import { updateIsReplay } from '@/modules/TryIt/redux/actions';
import { selectIsTryItReplay } from '@/modules/TryIt/redux/selectors';
import { pushModal } from '@/redux/modals/actions';
import { selectBrainId } from '@/redux/session/selectors';

export const headerItems = [
  'user',
  'conversation',
  'details',
  'context',
  'rating',
] as NavigationItem[];

export const useModalTryIt = (
  sessionId?: string,
  isAccountReplay?: boolean
) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const brainId = useSelector(selectBrainId);
  const { slug } = useAccount();
  const openedField = useRef(false);
  const isSession = !!sessionId;
  const isReplay = useSelector(selectIsTryItReplay);

  const clearSessionIdFromUrl = () => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete('session_id');
    return currentUrl.pathname.toString() + currentUrl.search.toString();
  };

  const handleReplayClick = useCallback(
    (clickedSession?: string) => {
      if (brainId) {
        dispatch(updateIsReplay(true));

        if (sessionId && !openedField.current) {
          openedField.current = true;
          dispatch(
            pushModal(MODAL_TRYIT, {
              brainId,
              sessionId,
            })
          );
        }
        if (clickedSession) {
          if (!openedField.current) {
            openedField.current = true;
          }
          dispatch(
            pushModal(MODAL_TRYIT, {
              brainId,
              sessionId: clickedSession,
            })
          );
        }

        navigate(
          `/${slug}/brains/${brainId}/logs?session_id=${
            sessionId ?? clickedSession
          }`,
          {
            replace: true,
          }
        );
      }
    },
    [brainId, dispatch, navigate, sessionId, slug]
  );

  const handleAccountReplayClick = useCallback(
    (clickedSession?: string, sessionBrainId?: string) => {
      dispatch(updateIsReplay(true));

      if (sessionId && !openedField.current) {
        openedField.current = true;
        dispatch(
          pushModal(MODAL_TRYIT, {
            sessionId: sessionId,
            isAccountReplay: true,
            brainId: sessionBrainId,
          })
        );
      }

      if (clickedSession) {
        if (!openedField.current) {
          openedField.current = true;
        }
        dispatch(
          pushModal(MODAL_TRYIT, {
            sessionId: clickedSession,
            isAccountReplay: true,
            brainId: sessionBrainId,
          })
        );
      }

      if (!sessionId) {
        navigate(
          {
            ...window.location,
            search: `${window.location.search}&session_id=${clickedSession}`,
          },
          {
            replace: true,
          }
        );
      }
    },
    [dispatch, navigate, sessionId]
  );

  const getHeaderItems = () => {
    if (isReplay && !isAccountReplay) {
      return headerItems;
    }
    if (isAccountReplay) {
      const headerItemsCopy = [...headerItems];

      return [...headerItemsCopy.slice(0, 2), ...headerItemsCopy.slice(4)];
    }
    return headerItems.slice(2, 4);
  };

  return {
    handleReplayClick,
    handleAccountReplayClick,
    isSession,
    headerItems: getHeaderItems(),
    clearSessionIdFromUrl,
  };
};
