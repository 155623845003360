import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Button from '@/components/atoms/Button/Button/Button';
import ChevronDown from '@/components/atoms/Icons/ChevronDown';
import { Icon } from '@/models/icon';
export const DynamicLabelButton = ({
  icon: IconComponent,
  currentLabel,
  type,
  handleMenuClick,
  color,
}: Props) => (
  <Button
    onClick={(e) => handleMenuClick(e, type)}
    variant="tertiary"
    size="small"
  >
    <Box
      sx={{
        display: 'flex',
        gap: 'var(--space-4)',
        svg: {
          marginRight: '0 !important',
        },
        alignItems: 'center',
      }}
    >
      <IconComponent color={color} />
      <Typography variant="body-medium">{currentLabel}</Typography>
      <ChevronDown />
    </Box>
  </Button>
);

type Props = {
  icon: React.ComponentType<Partial<Icon>>;
  currentLabel: string;
  type: 'sort' | 'status';
  handleMenuClick: (
    e: React.MouseEvent<HTMLButtonElement>,
    type: 'sort' | 'status'
  ) => void;
  color?: string;
};
